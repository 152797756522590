const auth = require('./authentication.json')
const publication = require('./publications.json');
const audio = require('./audio.json');
const payment = require('./payment.json')


export const getAuthSpecifications = ()=>{
    return auth;
}
export const getPublicationSpecifications = ()=>{
    return publication;
}
export const getAudioSpecifications = ()=>{
    return audio;
}
export const getPaymentSpecifications = ()=>{
    return payment;
}