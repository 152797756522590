import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Authentication from "./components/authentication";
import Publication from "./components/publication";
import NotFound from "./components/notfound";
import Payment from "./components/payment";
import Audio from "./components/audio";
class CustomRouter extends React.Component {
    constructor( props ) {
        super( props );
    }
   
render() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Authentication />} />
                <Route path="/authentication" element={<Authentication />} />
                <Route path="/publications" element={<Publication />} />
                 <Route path="/audio" element={<Audio />} />
                <Route path="/payments" element={<Payment />} />
                <Route path="*" element={<NotFound />}/> 

            </Routes>
        </Router>
    );
}
}
export default CustomRouter;