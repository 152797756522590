

import logo from '../rifaly-logo.png'
import menu from '../humburger.svg';
import { useState } from 'react';
const NavigationBar = ()=>{
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMenu = ()=>{
        setShowMenu(!showMenu);
    }
    return (<>
    <div className='d-none d-sm-block mb-5'>
        <div className='d-flex justify-content-between align-item-center'>
            <div className="logo"><img src={logo} alt="rifaly logo"/></div>
            <div className='d-flex'>
                <a href="/authentication" className='m-2'>Authentication</a>
                <a href="/publications" className='m-2'>Publication</a>
                <a href="/audio" className='m-2'>Audio</a>
                <a href="/payments" className='m-2'>Payment</a>
            </div>
        </div>
        </div>
        <div className='d-sm-none mb-5'>
        <div className='d-flex justify-content-between align-item-center'>
            <div className="logo-sm"><img src={logo} alt="rifaly logo"/></div>
            <img src={menu} onClick={handleShowMenu}/>
        </div>
            {showMenu ? <div className='d-flex flex-column justify-content-start align-items-start'>
                <a href="/authentication" className='m-2'>Authentication</a>
                <a href="/publications" className='m-2'>Publication</a>
                <a href="/audio" className='m-2'>Audio</a>
                <a href="/payments" className='m-2'>Payment</a>
            </div>:null}
        </div>
        
          
        </>
    )
}
export default NavigationBar;