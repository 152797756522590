import './App.css';
import CustomRouter from './router';
function App() {

  return (
    <div className="App">
     <CustomRouter />
    </div>
  );
}
export default App;
