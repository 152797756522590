import '../App.css';
import * as source from '../source/index'
import NavigationBar from './navigation';
function Payment() {

  const payment = source.getPaymentSpecifications();
  return (
    <div className="App">
    
      <div className='container m-2 mx-auto'>
      <NavigationBar/>
       <h2>Payment API</h2>
       <p>Version: {payment.document_version}</p>
       <p>Base URL: {payment.base_url}</p>
      {payment.api.map(api=>{
        return (<div key={api.endpoint} className='m-2 border p-2'>
        <div  className='bg-success text-start p-2 text-white'>
        <span>Endpoint: {api.endpoint}</span>
        </div>
        <div className='text-start'><label className="me-2">Description:</label>
        <span>{api.description}</span>
        </div>
        <div className='text-start'><label className="me-2">Method:</label>
        <span>{api.method}</span>
        </div>
        <div className='text-start'><label className="me-2">Mime Type:</label>
        <span>{api.mime}</span>
        </div>
        {api.body ?<div className='text-start bg-dark text-white p-1 codeblock'><label className="me-2">Body:</label>
        {Object.keys(api.body).map((key,index)=>{
          return (<div key={key} className='ms-2'>
            <label className='inner'>{key+": "}</label><span className='ms-2'>{JSON.stringify(api.body[key])}</span>
          </div>)
        })}
        
        </div>:null}
        {api.params ?<div className='text-start bg-dark text-white p-1 codeblock'><label className="me-2">Query Params:</label>
        {Object.keys(api.params).map((key,index)=>{
          return (<div key={key} className='ms-2'>
            <label className='inner'>{key+": "}</label><span className='ms-2'>{JSON.stringify(api.params[key])}</span>
          </div>)
        })}
        </div>:null}
        
      </div>)
      })}
      </div>
    </div>
  );
}

export default Payment;
