import '../App.css';
import NavigationBar from './navigation';
function NotFound() {
  return (
    <div className="App">
      <div className='container m-2 mx-auto'>
      <NavigationBar/>
       <h2 className='mt-5'>PAGE NOT FOUND</h2>
      </div>
    </div>
  );
}

export default NotFound;
